import { acceptHMRUpdate, defineStore } from 'pinia'
import { ref, computed } from 'vue'
import { isResponseError, useApi } from '../composable/useApi'
import { TeamModelType, type UserModel } from '../models/models'
import { fetchTeam } from '../api/team'
import * as Sentry from '@sentry/vue'
import { fetchVisibleTeams, fetchCurrentUser } from '../api/me'

export const useUserSession = defineStore('userSession', () => {
  const user = ref<UserModel>()
  const team = ref<TeamModelType>()
  const lastSelectedTeamId = useStorage<string>('last-selected-team', '')

  const api = useApi()

  const userExists = computed(() => user.value !== undefined)
  const teamExists = computed(() => team.value !== undefined)

  const teamId = computed(() => {
    return team.value?.teamId!
  })
  const teamName = computed(() => {
    return team.value?.teamName!
  })
  const teamPlan = computed(() => {
    return team.value?.plan!
  })
  const isFreePlan = computed(() => {
    return team.value?.plan!.isFreePlan
  })
  const isProPlan = computed(() => {
    return !team.value?.plan!.isFreePlan
  })
  const teamStatus = computed(() => {
    return team.value?.status!
  })

  const userIsAdminOrOwner = computed(() => {
    return user.value?.isSlackAdmin || userIsSubscriptionOwner.value
  })

  const userIsSubscriptionOwner = computed(() => {
    return teamPlan.value.isSubscriptionOwner(user.value?.userId)
  })

  async function logout() {
    user.value = undefined
    team.value = undefined
  }

  async function setTeam(teamId: string): Promise<TeamModelType | null> {
    const teamModel = await fetchTeam(api, teamId)
    if (isResponseError(teamModel)) {
      return null
    }

    team.value = new TeamModelType(teamModel)
    lastSelectedTeamId.value = teamId

    return team.value
  }

  async function setUser(): Promise<UserModel | null> {
    const userModelContainer = await fetchCurrentUser(api)
    if (isResponseError(userModelContainer) || userModelContainer.exists == false) {
      return null
    }

    user.value = userModelContainer.result

    Sentry.setUser({
      email: user.value.email,
      id: user.value.userId,
      username: user.value.slackHandle,
    })

    return user.value
  }

  async function hydrateUserAndTeam(force: boolean = false): Promise<boolean> {
    if (!force && userExists.value && teamExists.value) {
      return true
    }

    const user = await setUser()
    if (user == null) return false

    if (user.slackTeamId == undefined) {
      console.log('User has no team id, setting to first team')
      const teamResponse = await fetchVisibleTeams(api, {
        page: 1,
        pageSize: 10,
        sort: 'name|asc',
      })

      if (teamResponse._paging.totalRecords == 0) {
        return false
      }

      if (teamResponse.data.find((t) => t.teamId == lastSelectedTeamId.value)) {
        await setTeam(lastSelectedTeamId.value)
      } else await setTeam(teamResponse.data[0].teamId)
    } else {
      console.log('User has team id, setting to that')
      await setTeam(user.slackTeamId)
    }

    return true
  }

  async function reloadTeam(): Promise<void> {
    const teamModel = await fetchTeam(api, team.value!.teamId)
    if (isResponseError(teamModel) == false) {
      team.value = new TeamModelType(teamModel)
    }
  }

  return {
    user,
    userIsAdminOrOwner,
    userIsSubscriptionOwner,
    team,
    teamId,
    teamName,
    teamPlan,
    isFreePlan,
    isProPlan,
    teamStatus,
    userExists,
    teamExists,
    reloadTeam,
    setTeam,
    logout,
    hydrateUserAndTeam,
  }
})

/**
 * Pinia supports Hot Module replacement so you can edit your stores and
 * interact with them directly in your app without reloading the page.
 *
 * @see https://pinia.esm.dev/cookbook/hot-module-replacement.html
 * @see https://vitejs.dev/guide/api-hmr.html
 */
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useUserSession, import.meta.hot))
}
