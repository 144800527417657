<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { initDarkmode } from '/@src/stores/darkmode'

// This is the global app setup function
const { locale } = useI18n()

useHead(() => ({
  htmlAttrs: {
    lang: locale.value,
    dir: 'ltr'
  }
}))

/**
 * Initialize the darkmode watcher
 *
 * @see /@src/stores/darkmode
 */
initDarkmode()
</script>

<template>
  <div>
    <Suspense>
      <RouterView v-slot="{ Component }">
        <Transition name="fade-slow" mode="out-in">
          <component :is="Component" />
        </Transition>
      </RouterView>
    </Suspense>
    <ClientOnly>
      <VReloadPrompt app-name="Round Robin Dashboard" />
    </ClientOnly>
  </div>
</template>
