import axios, { type RawAxiosRequestHeaders, type AxiosInstance } from 'axios'
import { AuthenticationProperties as auth0 } from 'vue-auth0-plugin'
import { type ResponseError, type Unit } from '../models/models'
import { useUserSession } from '../stores/userSession'

let api: AxiosInstance

const apiAcceptingAnonymousRequests = ['api/install/custom']

export function createApi() {
  // Here we set the base URL for all requests made to the api
  api = axios.create({
    baseURL: import.meta.env.VITE_API_BASE_URL,
  })

  console.log(`Api initialized with baseUrl ${import.meta.env.VITE_API_BASE_URL}`)

  // We set an interceptor for each request to
  // include Bearer token to the request if user is logged in
  api.interceptors.request.use(async (config) => {
    // If the request is to an endpoint not in the list of endpoints accepting anonymous requests, ask for the Auth0 token
    if (!apiAcceptingAnonymousRequests.includes(config.url ?? '')) {
      try {
        const token = await auth0.getTokenSilently()

        if (token) {
          config.headers = {
            ...((config.headers as RawAxiosRequestHeaders) ?? {}),
            Authorization: `Bearer ${token}`,
          }
        }
      } catch (e) {
        // cannot get a token, login again
        auth0.loginWithRedirect()
      }
    }

    const userSession = useUserSession()
    if (userSession.teamExists && config.url && config.url.indexOf('{{teamId}}') > -1) {
      config.url = config.url.replace('{{teamId}}', userSession.team?.teamId ?? '')
    }

    return config
  })

  api.interceptors.response.use(
    function (response) {
      return response
    },
    async function (error) {
      if (error.response.status === 401) {
        auth0.loginWithRedirect()
        return Promise.reject(error)
      } else if (error.response.status === 403) {
        auth0.loginWithRedirect()
        return Promise.reject(error)
      }

      return error.response
    },
  )

  return api
}

export function useApi() {
  if (!api) {
    createApi()
  }
  return api
}

export function isResponseError(response: any): response is ResponseError {
  return (response as ResponseError).type !== undefined
}

export function isSpecificResponseStatus(
  responseError: ResponseError,
  status: number,
): boolean {
  return responseError.status === status
}

export function isUnit(response: any): response is Unit {
  return (response as Unit) !== undefined
}
