import type { AxiosInstance } from 'axios'
import {
  type ResponseError,
  type DutyPlanModel,
  type UserDashboardModel,
  type UserModel,
  type ResultContainerModel,
  type TeamBasicModel,
} from '../models/models'
import { useEndpointsStore } from '/@src/stores/endpoints'
import {
  type PaginationModel,
  type PaginationResponseModel,
} from '../models/pagingModels'

export async function fetchCurrentUser(
  api: AxiosInstance,
): Promise<ResultContainerModel<UserModel> | ResponseError> {
  const endpoints = useEndpointsStore()
  const response = await api.get<ResultContainerModel<UserModel> | ResponseError>(
    `${endpoints.me.get}`,
  )

  return response.data
}

export async function fetchVisibleTeams(
  api: AxiosInstance,
  pagination: PaginationModel,
): Promise<PaginationResponseModel<TeamBasicModel>> {
  const endpoints = useEndpointsStore()
  const qs = new URLSearchParams(pagination as unknown as Record<string, string>)

  const { data: teams } = await api.post<PaginationResponseModel<TeamBasicModel>>(
    `${endpoints.me.teams}`,
    { params: qs },
  )

  return teams
}

export async function removeUser(api: AxiosInstance, clean: boolean): Promise<void> {
  const endpoints = useEndpointsStore()
  await api.delete(`${endpoints.me.remove}/${clean}`)
}

export async function fetchUserDashboard(
  api: AxiosInstance,
): Promise<UserDashboardModel> {
  const endpoints = useEndpointsStore()
  const { data: user } = await api.get<UserDashboardModel>(`${endpoints.me.dashboard}`)

  return user
}

export async function fetchUserDutyPlan(api: AxiosInstance): Promise<DutyPlanModel[]> {
  const endpoints = useEndpointsStore()
  const { data: user } = await api.get<DutyPlanModel[]>(`${endpoints.me.dutyPlan}`)

  return user
}
