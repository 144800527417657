function initializeBeacon(e, t, n) {
  function a() {
    var e = t.getElementsByTagName('script')[0],
      n = t.createElement('script')
    ;(n.type = 'text/javascript'),
      (n.async = !0),
      (n.src = 'https://beacon-v2.helpscout.net'),
      e.parentNode.insertBefore(n, e)
  }
  if (
    ((e.Beacon = n =
      function (t, n, a) {
        e.Beacon.readyQueue.push({ method: t, options: n, data: a })
      }),
    (n.readyQueue = []),
    'complete' === t.readyState)
  )
    return a()
  e.attachEvent ? e.attachEvent('onload', a) : e.addEventListener('load', a, !1)

  window.Beacon('init', 'f08c4ede-b2c4-4a48-92a3-de0a93a1aa8d')
}

function enableDarkMode() {
  const prefersDark =
    window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches
  const setting = localStorage.getItem('color-schema') || 'auto'
  if (setting === 'dark' || (prefersDark && setting !== 'light'))
    document.documentElement.classList.toggle('is-dark', true)
}

initializeBeacon(window, document, window.Beacon || function () {})
enableDarkMode()
