import { type AxiosInstance } from 'axios'
import {
  type ResponseError,
  type TeamModel,
  type TeamFeaturesModelInput,
  type TeamStatusHistoryModel,
  type TeamStatusHistoryInputModel
} from '../models/models'
import { useEndpointsStore } from '/@src/stores/endpoints'
import {
  type PaginationModel,
  type PaginationResponseModel
} from '../models/pagingModels'

export async function fetchTeam(
  api: AxiosInstance,
  teamId: string
): Promise<TeamModel | ResponseError> {
  const endpoints = useEndpointsStore()
  const { data: team } = await api.get<TeamModel | ResponseError>(
    `${endpoints.teams.getById}`.replace('{{teamId}}', teamId)
  )

  return team
}

export async function updateTeamFeatures(
  api: AxiosInstance,
  teamId: string,
  model: TeamFeaturesModelInput
): Promise<TeamModel | ResponseError> {
  const endpoints = useEndpointsStore()
  const { data: team } = await api.post<TeamModel | ResponseError>(
    `${endpoints.teams.updateFeatures}`.replace('{{teamId}}', teamId),
    model
  )
  return team
}

export async function searchTeamUsers(
  api: AxiosInstance,
  teamId: string,
  pagination: PaginationModel,
  controller: AbortController | undefined
): Promise<PaginationResponseModel<string>> {
  const endpoints = useEndpointsStore()
  const qs = new URLSearchParams(pagination as unknown as Record<string, string>)

  const { data: users } = await api.post<PaginationResponseModel<string>>(
    `${endpoints.teams.searchTeamUsers}`.replace('{{teamId}}', teamId),
    {},
    { params: qs, signal: controller?.signal }
  )

  return users
}

export async function searchTeamStatusHistory(
  api: AxiosInstance,
  teamId: string,
  searchData: TeamStatusHistoryInputModel,
  pagination: PaginationModel,
  controller: AbortController | undefined
): Promise<PaginationResponseModel<TeamStatusHistoryModel>> {
  const endpoints = useEndpointsStore()
  const qs = new URLSearchParams(pagination as unknown as Record<string, string>)

  const { data: users } = await api.post<PaginationResponseModel<TeamStatusHistoryModel>>(
    `${endpoints.teams.searchStatusHistory}`.replace('{{teamId}}', teamId),
    searchData,
    { params: qs, signal: controller?.signal }
  )

  return users
}

export async function getTeamStatusHistoryById(
  api: AxiosInstance,
  teamId: string,
  statusHistoryId: string
): Promise<TeamStatusHistoryModel | ResponseError> {
  const endpoints = useEndpointsStore()

  const { data: history } = await api.get<TeamStatusHistoryModel | ResponseError>(
    `${endpoints.teams.getTeamStatusHistoryId}`
      .replace('{{teamId}}', teamId)
      .replace('{{id}}', statusHistoryId)
  )

  return history
}
