import { acceptHMRUpdate, defineStore } from 'pinia'

export interface EndpointsStoreModel {
  teams: {
    getById: string
    updateFeatures: string
    searchTeamUsers: string
    searchStatusHistory: string
    getTeamStatusHistoryId: string
  }
  me: {
    get: string
    teams: string
    dashboard: string
    dutyPlan: string
    remove: string
  }
  users: {
    getSpecificUser: string
    getSpecificUserBySlackId: string
    addUserRole: string
  }
  rotations: {
    getById: string
    dutyPlan: string
    dutyPlanHistory: string
    downloadDutyHistory: string
    getUsersByRotationId: string
    updateRotationUserSequence: string
    search: string
    create: string
    edit: string
    rotate: string
    setOnDuty: string
    delete: string
    removeUser: string
    addChannel: string
    removeChannel: string
    updateTemplates: string
    testTemplates: string
    picture: string
    getWebHookData: string
  }
  rotationSchedules: {
    createOrUpdate: string
  }
  slack: {
    users: {
      getById: string
      getByEmail: string
    }
    channels: {
      getById: string
      isMember: string
    }
    userGroups: {
      getById: string
    }
  }
  installation: {
    customInstall: string
  }
  payments: {
    plans: string
    create: string
    portal: string
    last_invoice: string
    upcoming_invoice: string
  }
  integrations: {
    google: {
      login: string
      exists: string
      logout: string
      clean: string
      sync: string
      calendars: string
      calendarsToUse: string
    }
    pagerduty: {
      login: string
      createAppData: string
      exists: string
      logout: string
    }
    opsGenie: {
      createAppData: string
      exists: string
      logout: string
    }
  }
  activities: {
    team: string
    rotation: string
  }
  externalSchedule: {
    rotation: string
    schedule: {
      getById: string
    }
  }
  mentions: {
    search: string
    getById: string
  }
}

export const useEndpointsStore = defineStore('endpointsStore', {
  state: (): EndpointsStoreModel => {
    return {
      me: {
        get: 'api/users/me',
        teams: 'api/users/me/teams',
        dashboard: 'api/users/me/dashboard/{{teamId}}',
        dutyPlan: 'api/users/me/duty/{{teamId}}',
        remove: 'api/users/me'
      },
      users: {
        getSpecificUser: 'api/users/{{userId}}',
        getSpecificUserBySlackId: 'api/users/{{teamId}}/{{slackUserId}}',
        addUserRole: 'api/users/{{userId}}/role'
      },
      teams: {
        getById: 'api/teams/{{teamId}}',
        updateFeatures: 'api/teams/{{teamId}}/features',
        searchTeamUsers: 'api/teams/{{teamId}}/users',
        searchStatusHistory: 'api/teams/{{teamId}}/status/history',
        getTeamStatusHistoryId: 'api/teams/{{teamId}}/status/history/{{id}}'
      },
      slack: {
        users: {
          getById: 'api/slack-data/{{teamId}}/users/{{userId}}',
          getByEmail: 'api/slack-data/{{teamId}}/users/email/{{userEmail}}'
        },
        channels: {
          getById: 'api/slack-data/{{teamId}}/channels/{{channelId}}',
          isMember: 'api/slack-data/{{teamId}}/channels/{{channelId}}/{{userId}}'
        },
        userGroups: {
          getById: 'api/slack-data/{{teamId}}/usergroups/{{userGroupId}}'
        }
      },
      rotationSchedules: {
        createOrUpdate: 'api/rotations/schedule/{{teamId}}/{{id}}'
      },
      rotations: {
        create: 'api/rotations/{{teamId}}',
        edit: 'api/rotations/{{teamId}}/{{id}}',
        rotate: 'api/rotations/{{id}}/duty',
        setOnDuty: 'api/rotations/{{id}}/duty',
        delete: 'api/rotations/{{teamId}}/{{id}}',
        getById: 'api/rotations/{{teamId}}/{{id}}',
        getUsersByRotationId: 'api/rotations/{{id}}/users',
        updateRotationUserSequence: 'api/rotations/{{id}}/users',
        search: 'api/rotations/{{teamId}}/search',
        removeUser: 'api/rotations/{{id}}/users/{{userId}}',
        dutyPlan: 'api/rotations/{{id}}/duty',
        dutyPlanHistory: 'api/rotations/{{id}}/duty/history',
        downloadDutyHistory:
          'api/rotations/{{id}}/duty/history/csv/{{fromUtc}}/{{toUtc}}',
        addChannel: 'api/rotations/{{id}}/channels',
        removeChannel: 'api/rotations/{{id}}/channels/{{channelId}}',
        updateTemplates: 'api/rotations/{{teamId}}/{{id}}/templates',
        testTemplates: 'api/rotations/{{teamId}}/{{id}}/templates',
        picture: 'api/rotations/{{teamId}}/{{id}}/picture',
        getWebHookData: 'api/rotations/webhook/{{teamId}}/{{id}}'
      },
      installation: {
        customInstall: 'api/install/custom'
      },
      payments: {
        plans: 'api/payments/plans',
        create: 'api/payments/checkout/{{teamId}}/create',
        portal: 'api/payments/billing/{{teamId}}/portal',
        last_invoice: 'api/payments/billing/{{teamId}}/invoice/last',
        upcoming_invoice: 'api/payments/billing/{{teamId}}/invoice/upcoming'
      },
      integrations: {
        google: {
          login: 'api/integrations/google/login/{{teamId}}',
          exists: 'api/integrations/google',
          logout: 'api/integrations/google',
          clean: 'api/integrations/google/clean',
          sync: 'api/integrations/google/sync',
          calendars: 'api/integrations/google/calendars',
          calendarsToUse: 'api/integrations/google'
        },
        pagerduty: {
          login: 'api/integrations/{{teamId}}/pagerduty/login',
          createAppData: 'api/integrations/{{teamId}}/pagerduty',
          exists: 'api/integrations/{{teamId}}/pagerduty',
          logout: 'api/integrations/{{teamId}}/pagerduty'
        },
        opsGenie: {
          createAppData: 'api/integrations/{{teamId}}/opsgenie',
          exists: 'api/integrations/{{teamId}}/opsgenie',
          logout: 'api/integrations/{{teamId}}/opsgenie'
        }
      },
      activities: {
        team: 'api/activities/team/{{teamId}}',
        rotation: 'api/activities/{{teamId}}/rotation/{{id}}'
      },
      externalSchedule: {
        rotation: 'api/integrations/{{teamId}}/{{partner}}/rotation/{{id}}',
        schedule: {
          getById: 'api/integrations/{{teamId}}/{{partner}}/schedules/{{id}}'
        }
      },
      mentions: {
        search: 'api/mention/search/{{teamId}}',
        getById: 'api/mention/{{teamId}}/{{id}}'
      }
    }
  }
})

/**
 * Pinia supports Hot Module replacement so you can edit your stores and
 * interact with them directly in your app without reloading the page.
 *
 * @see https://pinia.esm.dev/cookbook/hot-module-replacement.html
 * @see https://vitejs.dev/guide/api-hmr.html
 */
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useEndpointsStore, import.meta.hot))
}
